import { Autocomplete, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import locationService from '../Services/location.service';
import { device, location } from '../utils/routingConstants';

const SelectDevice = (props: any) => {
  const { locationId, deviceId, destRoute } = props;
  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState({});
  const history = useHistory();

  useEffect(() => {
    locationId &&
      locationService
        .getDevices(locationId)
        .then((response: any) => {
          setDevices(response.data);
          const optionSelected = response.data.find((option: any) => option.id === deviceId);
          setSelectedDevice(optionSelected);
        })
        .catch((e) => {
          console.log(e);
        });
  }, [deviceId, locationId]);

  const onChangeHandler = (event: any, data: any) => {
    if (data) {
      history.push(`${location}/${locationId}${device}/${data.id}${destRoute}`);
    }
  };

  return (
    <div className="select-device">
      <Autocomplete disablePortal id="device-select-box" onChange={onChangeHandler} options={devices} value={selectedDevice} getOptionLabel={(option: any) => option.name} sx={{ width: 300 }} renderInput={(params) => <TextField {...params} label="Device" />} />
    </div>
  );
};

export default SelectDevice;
