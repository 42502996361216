import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { DataGrid, GridColDef, GridComparatorFn } from '@mui/x-data-grid';

interface tagItem {
  id: number
  tag: string 
}

const ListTags = (props: any) => {
  const { list, isDeleteEnabled = false, updateTagList } = props;
  const [rows, setRows] = useState<tagItem[]>([]);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const tagComparator: GridComparatorFn<string> = (v1: string, v2: string) => v1.localeCompare(v2, undefined, { numeric: true, sensitivity: 'base' });

  useEffect(() => {
    const listArr = list.sort(tagComparator).map((listItem: string, index: number) => ({ tag: listItem, id: index }));
    setRows(listArr);
  }, [list]);

  const columns: GridColDef[] = [{ field: 'tag', headerName: 'Tags', sortComparator: tagComparator }];

  const onSelectionChange = (sRows: any) => {
    setSelectedRows(sRows);
  };

  const deleteTags = () => {
    const endList = rows.filter((rowItem, index) => !selectedRows.includes(index)).map((rowItem)=>rowItem.tag);
    updateTagList(endList)
  };

  return (
    <div className="list-tags">
      {isDeleteEnabled && (
        <Grid xs={12} className="delete-tag" justifyContent='flex-end' container>
          <Button variant="contained" color="error" onClick={deleteTags}>
            Delete
          </Button>
        </Grid>
      )}
      <DataGrid
        style={{ height: '640px', width: '100%' }}
        rows={rows}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        checkboxSelection
        onSelectionModelChange={onSelectionChange}
        initialState={{
          sorting: {
            sortModel: [
              {
                field: 'tag',
                sort: 'asc',
              },
            ],
          },
        }}
      />
    </div>
  );
};

export default ListTags;
