import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import PageTitle from '../components/PageTitle';
import SelectLocation from '../components/SelectLocation';
import statusService from '../Services/status.service';
import { status } from '../utils/routingConstants';
import StatusTable from '../components/StatusTable';
import { toast } from 'react-toastify';

const StatusPage = () => {
  const [statusList, setStatusList] = useState< Array < any >>([]);
  const [devicesList, setDevicesList] = useState<any>([]);
  const paramsList = useParams<any>();
  const { locationId } = paramsList;

  useEffect(() => {
    statusService
      .getStatus()
      .then((response: any) => setStatusList(response.data))
      .catch((error: any) => {
        toast.error('There was an error while getting statuses!!');
        console.log(error)
      });
  }, []);

  useEffect(() => {
    const locationItem = statusList.find((locationItem: any) => locationItem.locationId === parseInt(locationId));
    let devicesListData = []
    if(locationItem){
      const {devices} = locationItem;
      devicesListData = devices.map((deviceItem: any)=>{
        const {device} = deviceItem;
        return ({...device})
      })
    }
    setDevicesList(devicesListData);
  }, [locationId, statusList]);

  return (
    <div className="status-list">
      <PageTitle title="Status">
        <Grid container>
          <Grid xs={12} sx={{ m: 1 }} item>
            <SelectLocation locationId={locationId} destRoute={status} />
          </Grid>
        </Grid>
      </PageTitle>
      <br/><br/><br/><br/><br/><br/><br/>
      <Grid container spacing={2} className="main-content">
        <Grid xs={12} sx={{ m: 1 }} item>
          <StatusTable data={devicesList} />
        </Grid>
      </Grid>
    </div>
  );
};

export default StatusPage;
