import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import CreateButton from '../components/CreateButton';
import ListButtons from '../components/ListButtons';
import PageTitle from '../components/PageTitle';
import SelectLocation from '../components/SelectLocation';
import locationService from '../Services/location.service';
import IButtonData from '../types/button.type';
import { buttons } from '../utils/routingConstants';

import './ButtonsPage.scss';

const ButtonsPage = () => {
  const paramsList = useParams < any > ();
  const { locationId } = paramsList;
  const [buttonsList, setButtonsList] = useState([]);
  const [selectedButton, setSelectedButton] = useState <any>();

  useEffect(() => {
    if (locationId) {
      locationService
        .getButtons(parseInt(locationId))
        .then((response: any) => {
          setButtonsList(response.data);
        })
        .catch((e) => {
          toast.error("There was an error while getting buttons list!!");
          console.log(e);
        });
    }
  }, [locationId]);

  const updateButtonList = (list:IButtonData[]) => {
    if(Array.isArray(list)){
      const finalList = list.map((listItem, index)=>({...listItem, ordinal: index}));
      locationService.updateButtons(parseInt(locationId), finalList).then(()=>{
        toast("Buttons list updated!!");
        locationService
        .getButtons(parseInt(locationId))
        .then((response: any) => {
          setButtonsList(response.data);
        })
        .catch((e) => {
          toast.error("There was an error while getting buttons list!!");
          console.log(e);
        });
      }).catch((e)=>{
        console.log(e);
        toast.error("There was an error while updating buttons list!!");
      });
    }
  }

  const selectButton = (buttonData:any) => {
    if(selectedButton && selectedButton.title === buttonData.title){
      setSelectedButton(null);
    }else{
      setSelectedButton(buttonData)
    }
  };

  const createButton =(buttonItem: IButtonData) =>{
    console.log(buttonItem, selectedButton);
    const {title, responseText, ordinal} = buttonItem;
    if(title){
      buttonItem.title = title.trim();
    }
    if(responseText){
      buttonItem.responseText = responseText.trim();
    }
    const finalList:IButtonData[] = [...buttonsList];

    if(ordinal>=finalList.length){
      finalList.push(buttonItem);
    }else{
      if(selectedButton){
        finalList.splice(selectedButton.ordinal, 1);
      }
      finalList.splice(ordinal, 0, buttonItem);
    }
    setSelectedButton(null);
    updateButtonList(finalList);
  }

  return (
    <div className="buttons-page">
      <PageTitle title="Buttons">
        <Grid container>
          <Grid xs={12} sx={{ m: 1 }} item>
            <SelectLocation locationId={locationId} destRoute={buttons} />
          </Grid>
        </Grid>
      </PageTitle>
      <Grid container spacing={3} className="main-content">
        <Grid xs={6} item>
          <br/><br/><br/><br/><br/><br/><br/><br/>
          <ListButtons list={buttonsList} setList={updateButtonList} selectedButton={selectedButton} selectButton={selectButton}/>
        </Grid>
        <Grid xs={6} className="create-button-container" item>
        <br/><br/><br/><br/><br/><br/><br/><br/>
          <CreateButton createButton={createButton} selectedButton={selectedButton}/>
        </Grid>
      </Grid>
    </div>
  );
};

export default ButtonsPage;
