import generalTypes from '../Redux/types/common';

const INITIAL_STATE = {
  accessToken: null,
  authorityURL: null,
};

const reducerHandler = (action: any, state = INITIAL_STATE) => {
  const reducerObject = {
    [generalTypes.SET_ACCESS_TOKEN]: () => ({
      ...state,
      accessToken: action.value,
    }),
    [generalTypes.SET_AUTHORITY_URL]: () => ({
      ...state,
      authorityURL: action.value,
    }),
  };
  return (reducerObject[action.type] && reducerObject[action.type]()) || state;
};

const common = (state: any, action: any) => reducerHandler(action, state);

export default common;
