import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import EditNotification from '../components/EditNotification';
import NotificationTable from '../components/NotificationTable';
import PageTitle from '../components/PageTitle';
import SelectLocation from '../components/SelectLocation';
import locationService from '../Services/location.service';
import INotificationData from '../types/notification.type';
import { notifications } from '../utils/routingConstants';

const NotificationPage = () => {
  const paramsList = useParams < any > ();
  const { locationId } = paramsList;
  const [notificationList, setNotificationList] = useState([]);
  const [selectedNotification, setSelectedNotification] = useState <any>();

  useEffect(() => {
    if (locationId) {
      locationService
        .getNotifications(parseInt(locationId))
        .then((response: any) => {
          setNotificationList(response.data);
        })
        .catch((e) => {
          toast.error('There was an error while getting notifications!!');
          console.log(e);
        });
    }
  }, [locationId]);

  const updateNotificationList = (list:INotificationData[]) => {
    if(Array.isArray(list)){
      locationService.updateNotifications(parseInt(locationId), list).then(()=>{
        toast('Notifications updated!!');
        locationService
        .getNotifications(parseInt(locationId))
        .then((response: any) => {
          setNotificationList(response.data);
        })
        .catch((e) => {
          toast.error('There was an error while getting notifications!!');
          console.log(e);
        });
      }).catch((e) => {
        toast.error('There was an error while updating notifications!!');
        console.log(e);
      });
    }
  }

  const selectNotification = (notificationData:INotificationData) => {
    if(selectedNotification && selectedNotification.rowId === notificationData.rowId){
      setSelectedNotification(null);
    }else{
      setSelectedNotification(notificationData)
    }
  };

  const createNotification =(notificationItem: INotificationData) =>{
    console.log(notificationItem, selectedNotification);
    const finalList:INotificationData[] = [...notificationList];
    const notificationIndex = finalList.findIndex(({rowId}:INotificationData)=>rowId === notificationItem.rowId);
    if(notificationIndex === -1){
      finalList.push(notificationItem);
    }else{
      finalList[notificationIndex] = notificationItem;
    }
    setSelectedNotification(null);
    updateNotificationList(finalList);
  }

  return (
    <div className="notifications-page">
      <PageTitle title="Notifications">
        <Grid container>
          <Grid xs={12} sx={{ m: 1 }} item>
            <SelectLocation locationId={locationId} destRoute={notifications} />
          </Grid>
        </Grid>
      </PageTitle>
      <br/><br/><br/><br/><br/><br/><br/>
      <Grid container spacing={2} className="main-content">
        <Grid xs={6} item>
          <NotificationTable data={notificationList} selectNotification={selectNotification}/>
        </Grid>
        <Grid xs={6} item>
          <EditNotification createNotification={createNotification}  selectedNotification={selectedNotification}/>
        </Grid>
      </Grid>
    </div>
  );
};

export default NotificationPage;
