import React from 'react';
import { useMsal } from '@azure/msal-react';
import { Button } from '@mui/material';
import { loginRequest } from '../config/appConfig';
import { useHistory } from 'react-router';
import { defaultRoute } from '../utils/routingConstants';

const SignInButton = () => {
  const { instance } = useMsal();
  const history = useHistory();

  const handleLogin = (instance: any) => {
    instance
      .loginRedirect(loginRequest)
      .then(() => history.replace(defaultRoute))
      .catch((e: any) => {
        console.error(e);
      });
  };

  return (
    <Button onClick={() => handleLogin(instance)} variant="contained">
      Sign in
    </Button>
  );
};
export default SignInButton;
