import React, { useEffect } from 'react';
import { Button, FormControl, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import IDeviceData from '../types/device.type';

const CreateDevice = (props: any) => {
  const { handleSubmit, register, reset, setValue } = useForm();
  const { createDevice, selectedDevice } = props;
  const onSubmit = (data: IDeviceData) => {
    if (data) {
      createDevice(data);
    }
  };

  useEffect(() => {
    if (selectedDevice) {
      Object.entries(selectedDevice).forEach(([name, value]: any) => setValue(name, value));
    } else {
      reset({ name: '', id: '', serialNumber: '' });
    }
  }, [selectedDevice, setValue, reset]);

  return (
    <div className="create-tag">
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <FormControl fullWidth sx={{ m: 1 }}>
          <TextField
            id="device-name"
            label="Device Name"
            variant="filled"
            {...register('name')}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <FormControl fullWidth sx={{ m: 1 }}>
          <TextField
            id="serial-number"
            label="Serial Number"
            variant="filled"
            {...register('serialNumber')}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <FormControl fullWidth sx={{ m: 1 }}>
          <TextField
            id="device-id"
            label="ID"
            variant="filled"
            {...register('id')}
            disabled={!!selectedDevice}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <Button variant="contained" type="submit" sx={{ m: 1 }}>
          Submit
        </Button>
      </form>
    </div>
  );
};

export default CreateDevice;
