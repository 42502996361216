import React from 'react';
import TableComponent from './TableComponent';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const NotificationTable = (props: any) => {
  const { data, selectNotification } = props;
  const columns = [
    { name: 'contactInfo', label: 'Contact Info' },
    {
      name: 'active',
      label: 'Active',
      options: {
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
          if (data[dataIndex].active) {
            return <CheckCircleIcon />;
          }
          return null;
        },
      },
    },
  ];

  const onRowSelectionChange = (currentRowsSelected: any, allRowsSelected: any, rowsSelected: any) => {
    const { index } = currentRowsSelected[0];
    selectNotification(data[index]);
  };

  return (
    <div className="notification-table">
      <TableComponent title="Contacts" columns={columns} data={data} onRowSelectionChange={onRowSelectionChange} selectableRows="single" />
    </div>
  );
};

export default NotificationTable;
