import React, { Fragment, useState } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/PeopleAlt';
import LocationIcon from '@mui/icons-material/LocationCityOutlined';
import SmartButtonIcon from '@mui/icons-material/SmartButton';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import DevicesIcon from '@mui/icons-material/Devices';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import AlignHorizontalLeftOutlinedIcon from '@mui/icons-material/AlignHorizontalLeftOutlined';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useHistory } from 'react-router';
import { all, buttons, create, createCustomerRoute, createLocationRoute, customer, defaultRoute, devices, guestNameType, location, notifications, response, settings, status, tagGroup, tags } from '../utils/routingConstants';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, List } from '@mui/material';

const MainListItems = () => {
  const history = useHistory();
  const [openMenus, setOpenMenus] = useState <any>({
    openCustomers: true,
    openLocations: true,
    openSettings: true,
    openDevices: true,
    openResponses: true,
    openTags: true,
  });

  const toggleOpenMenuHandler = (menuName: string) => {
    const toggleData = openMenus[menuName];
    setOpenMenus({ ...openMenus, [menuName]: !toggleData });
  };

  const gotoLink = (route: string) => history.push(route);

  const { openCustomers, openLocations, openDevices, openResponses, openTags } = openMenus;

  return (
    <Fragment>
      <ListItemButton onClick={() => gotoLink(defaultRoute)} title="Home">
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItemButton>
      <ListItemButton onClick={() => toggleOpenMenuHandler('openCustomers')} title="Customers">
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Customers" />
        {openCustomers ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openCustomers} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} onClick={() => gotoLink(`${customer}${all}`)} title="All Customers">
            <ListItemIcon>
              <AlignHorizontalLeftOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="All Customers" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} onClick={() => gotoLink(createCustomerRoute)} title="Create Customer">
            <ListItemIcon>
              <AddCircleOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Create Customer" />
          </ListItemButton>
        </List>
      </Collapse>
      <ListItemButton onClick={() => toggleOpenMenuHandler('openLocations')} title="Locations">
        <ListItemIcon>
          <LocationIcon />
        </ListItemIcon>
        <ListItemText primary="Locations" />
        {openLocations ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openLocations} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} onClick={() => gotoLink(`${location}${all}`)} title="All Locations">
            <ListItemIcon>
              <AlignHorizontalLeftOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="All Locations" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} onClick={() => gotoLink(createLocationRoute)} title="Create Location">
            <ListItemIcon>
              <AddCircleOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Create Location" />
          </ListItemButton>
        </List>
      </Collapse>
      <ListItemButton onClick={() => gotoLink(`${location}${buttons}`)} title="Buttons">
        <ListItemIcon>
          <SmartButtonIcon />
        </ListItemIcon>
        <ListItemText primary="Buttons" />
      </ListItemButton>
      <ListItemButton onClick={() => gotoLink(`${location}${settings}/all`)} title="All Settings">
        <ListItemIcon>
          <DisplaySettingsIcon />
        </ListItemIcon>
        <ListItemText primary="All Settings" />
      </ListItemButton>
      <ListItemButton sx={{ pl: 4 }} onClick={() => gotoLink(`${location}${settings}${guestNameType}`)} title="Guest Prefix">
            <ListItemIcon>
              <AccessibilityIcon />
            </ListItemIcon>
            <ListItemText primary="Guest Prefix" />
          </ListItemButton>
      <ListItemButton onClick={() => toggleOpenMenuHandler('openTags')} title="Tags">
        <ListItemIcon>
          <LocalOfferIcon />
        </ListItemIcon>
        <ListItemText primary="Tags" />
        {openTags ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openTags} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
        <ListItemButton sx={{ pl: 4 }} onClick={() => gotoLink(`${location}${tags}`)} title="Tags">
            <ListItemIcon>
              <LocalOfferIcon />
            </ListItemIcon>
            <ListItemText primary="Tags" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} onClick={() => gotoLink(`${location}${settings}${tagGroup}`)} title="Tag Group">
            <ListItemIcon>
              <LocalOfferIcon />
            </ListItemIcon>
            <ListItemText primary="Tag Group" />
          </ListItemButton>
        </List>
      </Collapse>
      <ListItemButton onClick={() => toggleOpenMenuHandler('openDevices')}  title="Devices">
        <ListItemIcon>
          <DevicesOtherIcon />
        </ListItemIcon>
        <ListItemText primary="Devices" />
        {openDevices ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openDevices} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} onClick={() => gotoLink(`${location}${devices}`)} title="Manage Devices">
            <ListItemIcon>
              <DevicesIcon />
            </ListItemIcon>
            <ListItemText primary="Manage Devices" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} onClick={() => gotoLink(`${location}${devices}${tags}`)} title="Manage Devices Tags">
            <ListItemIcon>
              <LocalOfferIcon />
            </ListItemIcon>
            <ListItemText primary="Manage Devices Tags" />
          </ListItemButton>
        </List>
      </Collapse>
      <ListItemButton onClick={() => gotoLink(`${location}${status}`)}  title="Status">
        <ListItemIcon>
          <ChromeReaderModeIcon />
        </ListItemIcon>
        <ListItemText primary="Status" />
      </ListItemButton>
      <ListItemButton onClick={() => gotoLink(`${location}${notifications}`)}  title="Notifications">
        <ListItemIcon>
          <ContactPhoneIcon />
        </ListItemIcon>
        <ListItemText primary="Notifications" />
      </ListItemButton>
      <ListItemButton onClick={() => toggleOpenMenuHandler('openResponses')} title="Responses">
        <ListItemIcon>
          <StickyNote2Icon />
        </ListItemIcon>
        <ListItemText primary="Responses" />
        {openResponses ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openResponses} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} onClick={() => gotoLink(`${location}${response}${create}`)} title="Create Custom Response">
            <ListItemIcon>
              <AddCircleOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Create Custom Response" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} onClick={() => gotoLink(`${location}${response}${all}`)} title="List Custom Response">
            <ListItemIcon>
              <CalendarMonthIcon />
            </ListItemIcon>
            <ListItemText primary="List Custom Response" />
          </ListItemButton>
        </List>
      </Collapse>
    </Fragment>
  );
};

export default MainListItems;
