import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import CreateSetting from '../components/CreateSetting';
import GuestNamePrefix from '../components/GuestNamePrefix';
import ListSettings from '../components/ListSettings';
import PageTitle from '../components/PageTitle';
import SelectLocation from '../components/SelectLocation';
import locationService from '../Services/location.service';
import ISettingsData from '../types/settings.type';
import { settingsConstants } from '../utils/appConstants';
import { settings } from '../utils/routingConstants';
interface paramsTypes {
  settingName: string,
  locationId: string,
}

const Settings = () => {
  const paramsList = useParams<paramsTypes>();
  const { locationId, settingName } = paramsList;
  console.log('settingText', settingName)
  const settingText = settingsConstants[settingName] || settingName;
  const [settingsList, setSettingsList] = useState([]);
  useEffect(() => {
    if (locationId) {
      locationService
        .getSettings(parseInt(locationId), settingText)
        .then((response: any) => {
          setSettingsList(response.data);
        })
        .catch((e) => {
          toast.error("There was an error while getting settings list!!");
          console.log(e);
        });
    }
  }, [locationId, settingText]);

  const updateSettingsList = (list: ISettingsData[]) => {
    if (Array.isArray(list)) {
      locationService.updateSettings(parseInt(locationId), settingText, list).then(() => {
        toast('Settings updated!!');
        locationService
          .getSettings(parseInt(locationId), settingText)
          .then((response: any) => {
            setSettingsList(response.data);
          })
          .catch((e) => {
            toast.error("There was an error while getting settings list!!");
            console.log(e);
          });
      });
    }
  };

  const createSetting = (settingItem: ISettingsData) => {
    const finalList: ISettingsData[] = [...settingsList];
    if (settingItem.name === 'guest_name_type') {
      const index = finalList.findIndex((listItem: ISettingsData) => listItem.name === 'guest_name_type');
      if (index > -1) {
        finalList[index] = settingItem;
      } else {
        finalList.push(settingItem);
      }
    } else {
      finalList.push(settingItem);
    }
    updateSettingsList(finalList);
  };

  const deleteSetting = (settingItem: ISettingsData) => {
    const newList = [...settingsList];
    return newList.filter((listItem: ISettingsData) => settingItem.value !== listItem.value);
  };

  const getValue = () => {
    const valueObject = settingsList.find((settingItem: ISettingsData) => settingItem.name === settingText);
    return valueObject;
  };

  return (
    <div className="settings-page">
      <PageTitle title="Settings">
        <Grid container>
          <Grid xs={12} sx={{ m: 1 }} item>
            <SelectLocation locationId={locationId} destRoute={`${settings}/${settingName}`} />
          </Grid>
        </Grid>
      </PageTitle>
      <br/><br/><br/><br/><br/><br/><br/>
      <Grid container spacing={2} className="main-content">
        {settingName !== 'guest_name_type' ? (
          <>
            <Grid xs={6} item>
              <ListSettings list={settingsList} deleteSetting={deleteSetting} filter={settingText} />
            </Grid>
            <Grid xs={6} item>
              <CreateSetting createSetting={createSetting} settingName={settingText} />
            </Grid>
          </>
        ) : (
          <Grid xs={6} item>
            <GuestNamePrefix createSetting={createSetting} settingName={settingText} getValue={getValue} />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default Settings;
