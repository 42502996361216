import { Grid } from '@mui/material';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import PageTitle from '../components/PageTitle';
import ResponseForm from '../components/ResponseForm';
import SelectLocation from '../components/SelectLocation';
import locationService from '../Services/location.service';
import { create, response } from '../utils/routingConstants';

const CreateResponse = () => {
  const paramsList = useParams<any>();
  const { locationId } = paramsList;

  const onSubmit = (data: any) => {
    const { type } = data;
    data.rowId = null;
    locationService.getResponses(locationId, type).then((response) => {
      const newList = [...response.data];
      newList.push(data);
      locationService.updateResponses(locationId, type, newList).then(()=>{
        toast('Response List updated!!');
      });
    }).catch((error) => {
      toast.error("There was an error while updating response list!!");
      console.log(error);
    })
  };

  return (
    <div className="create-custom-response">
      <PageTitle title="Create Response">
        <Grid container>
          <Grid xs={12} sx={{ m: 1 }} item>
            <SelectLocation locationId={locationId} destRoute={`${response}${create}`} />
          </Grid>
        </Grid>
      </PageTitle>
      <br/><br/><br/><br/><br/><br/><br/>
      <Grid container spacing={2} className="main-content">
        <Grid xs={12} sx={{ m: 1 }} item>
          <ResponseForm onSubmit={onSubmit} />
        </Grid>
      </Grid>
    </div>
  );
};

export default CreateResponse;
