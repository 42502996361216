import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid, TextField, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import IOSSlider from '../components/IOSSlider';
import PageTitle from '../components/PageTitle';
import SelectLocation from '../components/SelectLocation';
import locationService from '../Services/location.service';
import ISettingsData from '../types/settings.type';
import { settingsConstants } from '../utils/appConstants';
import { settings as settingRoute } from '../utils/routingConstants';

const ValueLabelComponent = (props: any) => {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement="bottom" title={value} open={true}>
      {children}
    </Tooltip>
  );
};

const AllSettings = () => {
  const paramsList = useParams<any>();
  const { locationId } = paramsList;
  const [settingsList, setSettingsList] = useState<ISettingsData[]>([]);

  let timer = setTimeout(() => {}, 1);
  useEffect(() => {
    if (locationId) {
      locationService
        .getAllSettings(parseInt(locationId))
        .then((response: any) => {
          settingListChange(response.data);
        })
        .catch((e) => {
          toast.error('There was an error while getting settings!!');
          console.log(e);
        });
    }
  }, [locationId]);

  const settingListChange = (list: ISettingsData[]) => {
    const totalSettingList = Object.keys(settingsConstants.settingControl).map((objectItem) => {
      const item = list.find((listItem) => listItem.name === objectItem);
      if (item) {
        return item;
      } else {
        return { name: objectItem, value: settingsConstants.settingControl[objectItem].value };
      }
    });
    setSettingsList(totalSettingList);
  };

  const updateSettingsList = (list: ISettingsData[], settingName: string) => {
    if (Array.isArray(list)) {
      locationService.updateSettings(parseInt(locationId), settingName, list).then(() => {
        toast('Setting updated!!');
        locationService
          .getAllSettings(parseInt(locationId))
          .then((response: any) => {
            settingListChange(response.data);
          })
          .catch((e) => {
            toast.error('There was an error while getting settings!!');
            console.log(e);
          });
      }).catch((e) => {
        toast.error('There was an error while updating settings!!');
        console.log(e);
      });
    }
  };

  const onSettingChange = (settingName: string, value: any) => {
    const settingArray: ISettingsData[] = [];
    settingArray.push({
      name: settingName,
      value: value.toString(),
    });
    clearTimeout(timer);
    timer = setTimeout(() => {
      console.log(settingArray);
      updateSettingsList(settingArray, settingName);
    }, 400);
  };

  return (
    <div className="settings-page">
      <PageTitle title="Settings">
        <Grid container>
          <Grid xs={12} sx={{ m: 1 }} item>
            <SelectLocation locationId={locationId} destRoute={`${settingRoute}/all`} />
          </Grid>
        </Grid>
      </PageTitle>
      <br/><br/><br/><br/><br/><br/><br/>
      <Grid container spacing={2} className="main-content">
        <Grid xs={12} sx={{ m: 1 }} item>
          {settingsList.map((settingItem: ISettingsData) => {
            const { name, value } = settingItem;
            if (!settingsConstants.settingControl.hasOwnProperty(name)) {
              return null;
            }
            const { type, properties, label = '' } = settingsConstants.settingControl[name];
            if (type === 'slider') {
              return (
                <FormControl fullWidth sx={{ m: 2 }}>
                  <Typography gutterBottom>{label}</Typography>
                  <IOSSlider
                    value={value}
                    onChange={(e, newValue) => onSettingChange(name, newValue)}
                    valueLabelDisplay="on"
                    {...properties}
                    components={{
                      ValueLabel: ValueLabelComponent,
                    }}
                  />
                </FormControl>
              );
            } else if (type === 'checkbox') {
              return (
                <FormGroup sx={{ m: 2 }}>
                  <FormControlLabel control={<Checkbox checked={!!value} />} label={label} onChange={(e, newValue) => onSettingChange(name, newValue)} />
                </FormGroup>
              );
            } else {
              return (
                <FormControl fullWidth sx={{ m: 2 }}>
                  <TextField label={label} variant="filled" value={value} onChange={(e) => onSettingChange(name, e.target.value)} />
                </FormControl>
              );
            }
          })}
        </Grid>
      </Grid>
    </div>
  );
};

export default AllSettings;
