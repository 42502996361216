// import { toast } from 'react-toastify';
import { IResponseData } from '../types/response.type';
// import { days } from './appConstants';

export const getDayName = (dateStr: Date, locale: string | string[] | undefined) => {
  var date = new Date(dateStr);
  return date.toLocaleDateString(locale, { weekday: 'long' });
};

export const structureSchedule = (res: any, locationId: any, setScheduleItems: any) => {
  const list: any[] = [];
  res.data.forEach((listItem: IResponseData) => {
    const days = listItem.enabledDaysOfWeek ? listItem.enabledDaysOfWeek.split(',') : [];
    days.forEach((dayItem: string) => {
      const dateNumber = 17 + parseInt(dayItem);
      list.push({
        ...listItem,
        startDate: `2022-04-${dateNumber}T${listItem.startHour < 10 ? `0${listItem.startHour}` : listItem.startHour}`,
        endDate: `2022-04-${dateNumber}T${listItem.endHour < 10 ? `0${listItem.endHour}` : listItem.endHour}`,
        title: listItem.template,
        setScheduleItems,
        locationId,
      });
    });
  });
  return list;
};


// export const isOverLaps = (list: any, item: any) => {
//   for(let i =0;i<list.length;i++){
//     //const listItem = list[i];
//     //const {enabledDaysOfWeek, endHour, rowId, startHour} = listItem;
//     // const {enabledDaysOfWeek,  rowId} = listItem;
//     // let overlapDays = [];
//     // if(item.rowId){
//     //   if(item.rowId !== rowId){
//     //     overlapDays = enabledDaysOfWeek.split(',').filter((dayItem:number)=> item.enabledDaysOfWeek.includes(dayItem));
//     //   }
//     // }else{
//     //   overlapDays = enabledDaysOfWeek.split(',').filter((dayItem:number)=> item.enabledDaysOfWeek.includes(dayItem));
//     // }
//     // if(overlapDays.length > 0 && item.startHour <= endHour && startHour <= item.endHour ){
//     //   overlapDays = overlapDays.map((dayItem: any)=>days[dayItem]);
//     //   toast.error(`Schedule is getting overlapped for ${overlapDays.toString()}!`);
//     //   return true;
//     // }
//   }
//   return false;
// }

export const getResponseListOnEdit = (responses: IResponseData[], data: IResponseData) => {
  if (!data.rowId) {
    responses.push(data);
    return responses;
  }
  return responses.map((responseItem: IResponseData) => {
    if (responseItem.rowId === data.rowId) {
      return data;
    }
    return responseItem;
  });
};
export const getResponseListOnDelete = (responses: IResponseData[], data: IResponseData) => responses.filter((responseItem: IResponseData) => responseItem.rowId !== data.rowId);

export const extractNumber = (txt: string) => {
  const numb = txt.match(/\d/g);
  return numb && numb.join('');
};

export const removeNumber = (txt: string) => {
  const numb = txt.replace(/\d/g, '');
  return numb;
};
