import { AuthenticationResult, EventType, InteractionType } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { ConnectedRouter } from 'connected-react-router';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { loginRequest } from './config/appConfig';
import { setAccessToken, setAuthorityURL } from './Redux/actions/common'
import { history, msalInstance } from './reduxStore';
import Routes from './Routes';

function App(props: any) {
  const { setToken, setAuthority } = props;

  useEffect(() => {
    msalInstance.addEventCallback((message) => {
      switch (message.eventType) {
        case EventType.LOGIN_SUCCESS:
          if (message.interactionType === InteractionType.Redirect) {
            let authenticationResult = message.payload as AuthenticationResult
            let authority = authenticationResult.authority;
            setAuthority(authority);
            if (authenticationResult.account) {
              let account = authenticationResult.account;
              msalInstance.setActiveAccount(account);
              msalInstance.acquireTokenSilent({ ...loginRequest, account: account, authority: authority });
            }
          }
          break;
        case EventType.ACQUIRE_TOKEN_SUCCESS:
          let authenticationResult = message.payload as AuthenticationResult
          let accessToken = authenticationResult.accessToken;
          setToken(accessToken);
          break;
      }
    });
    
  }, [setAuthority, setToken]);

  return (
    <MsalProvider instance={msalInstance}>
      <ConnectedRouter history={history}>
        <Routes />
      </ConnectedRouter>
    </MsalProvider>
  );
}

const mapDispatchToProps = (dispatch: any) => ({
  setToken: (value: string) => dispatch(setAccessToken(value)),
  setAuthority: (value: string) => dispatch(setAuthorityURL(value)),
});

export default connect(null, mapDispatchToProps)(App);
