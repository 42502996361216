import { useIsAuthenticated } from '@azure/msal-react';
import { Grid } from '@mui/material';
import PageTitle from '../components/PageTitle';

const Home = () => {
  const isAuthenticated = useIsAuthenticated();
  return (
    <div className="home">
      <PageTitle title="Home" />
      <br/><br/><br/><br/><br/><br/><br/>
      <Grid container className='main-content less-content'>
        <Grid xs={12} sx={{ m: 1 }} item>
          {isAuthenticated ? <span>Welcome to OhWaiter Support Console!</span> : <span>Please SignIn to continue!</span>}
        </Grid>
      </Grid>
    </div>
  );
};

export default Home;
