import React from 'react';
import { Button, FormControl, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { extractNumber, removeNumber } from '../utils/stringUtils';

const CreateTag = (props: any) => {
  const { handleSubmit, register, reset } = useForm();
  const { createTag } = props;
  const onSubmit = (data: any) => {
    if (!data) {
      return;
    }

    if (data.tag.includes(',')) {
      const list = data.tag.split(',');
      list.forEach((element: string) => {
        if (!!element) {
          createTag(element);
        }
      });
    }else if (data.tag.includes('-')) {
      const tagsRange = data.tag.split('-');
      const limit1 = extractNumber(tagsRange[0]) || 0;
      const limit2 = extractNumber(tagsRange[1]) || 0;
      const literal = removeNumber(tagsRange[0]);
      for (let i: any = limit1; i <= limit2; i++) {
        createTag(`${literal}${i}`);
      }
    } else {
      createTag(data.tag);
    }
    reset({ tag: '' });
  };
  return (
    <div className="create-tag">
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <FormControl fullWidth sx={{ m: 1 }}>
          <TextField id="tag-name" label="Tag" variant="filled" {...register('tag')} />
          <Typography variant="subtitle1">Add Tags with ',' to add specific tags. E.g. P1,P5.</Typography>
          <Typography variant="subtitle1">OR Define range with '-'. E.g. P1-P5.</Typography>
        </FormControl>
        <Button variant="contained" type="submit" sx={{ m: 1 }}>
          Submit
        </Button>
      </form>
    </div>
  );
};

export default CreateTag;
