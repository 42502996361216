import api from './api';
import { ICreateLocationData, ILocationData } from '../types/location.type';
import IButtonData from '../types/button.type';
import ISettingsData from '../types/settings.type';
import IDeviceData from '../types/device.type';
import { IResponseData } from '../types/response.type';
import IStatusData from '../types/status.type';
import INotificationData from '../types/notification.type';
const API_URL = '/location';

class LocationDataService {
    getAll() {
        return api.get<Array<ILocationData>>('/locations');
    }
    create(data: ICreateLocationData) {
        return api.post<any>(API_URL, data);
    }

    getButtons(locationId: number) {
        return api.get<Array<IButtonData>>(`${API_URL}/${locationId}/buttons`);
    }
    updateButtons(locationId: number, buttonsData: Array<IButtonData>) {
        return api.post<Boolean>(`${API_URL}/${locationId}/buttons`, buttonsData);
    }


    getAllSettings(locationId: number) {
        return api.get<Array<ISettingsData>>(`${API_URL}/${locationId}/settings`);
    }
    getSettings(locationId: number, name: string) {
        return api.get<Array<ISettingsData>>(`${API_URL}/${locationId}/settings/${name}`);
    }
    updateSettings(locationId: number, name: string, settingsData: Array<ISettingsData>,) {
        return api.post<Boolean>(`${API_URL}/${locationId}/settings/${name}`, settingsData);
    }

    getTags(locationId: number) {
        return api.get<Array<string>>(`${API_URL}/${locationId}/tags`);
    }
    updateTags(locationId: number, tagsData: Array<string>) {
        return api.post<Boolean>(`${API_URL}/${locationId}/tags`, tagsData);
    }

    getDevices(locationId: number) {
        return api.get<Array<IDeviceData>>(`${API_URL}/${locationId}/devices`);
    }
    updateDevices(locationId: number, devicesData: Array<IDeviceData>) {
        return api.post<Boolean>(`${API_URL}/${locationId}/devices`, devicesData);
    }

    getDeviceTags(locationId: number, deviceId: string) {
        return api.get<Array<string>>(`${API_URL}/${locationId}/device/${deviceId}/tags`);
    }
    updateDeviceTags(locationId: number, deviceId: string, tagsData: Array<string>) {
        return api.post<Boolean>(`${API_URL}/${locationId}/device/${deviceId}/tags`, tagsData);
    }

    getResponses(locationId: number, type: string) {
        return api.get<Array<IResponseData>>(`${API_URL}/${locationId}/responses/${type}`);
    }
    updateResponses(locationId: number, type: string, responsesData: Array<IResponseData>) {
        return api.post<Boolean>(`${API_URL}/${locationId}/responses/${type}`, responsesData);
    }

    checkStatus(locationId: number) {
        return api.get<IStatusData>(`${API_URL}/${locationId}/ready`);
    }

    getNotifications(locationId: number) {
        return api.get<Array<INotificationData>>(`${API_URL}/${locationId}/notifications`);
    }
    updateNotifications(locationId: number, notificationData: INotificationData[]) {
        return api.post<Boolean>(`${API_URL}/${locationId}/notifications`, notificationData);
    }
}

export default new LocationDataService();
