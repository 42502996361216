import { useEffect, useState } from 'react';
import { Grid, IconButton } from '@mui/material';
import { ICustomerData } from '../types/customer.type';
import customerService from '../Services/customer.service';
import { LocationCity } from '@mui/icons-material';
import { customer, location } from '../utils/routingConstants';
import { useHistory } from 'react-router';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import PageTitle from '../components/PageTitle';
import { toast } from 'react-toastify';

const Customers = () => {
  const [customerList, setCustomerList] = useState <ICustomerData[]> ( []);
  const history = useHistory();

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Id', width: 90 },
    { field: 'name', headerName: 'Customer Name', flex: 1 },
    {
      field: 'navigationButton',
      headerName: '',
      width: 90,
      renderCell: (params)=>(
        <IconButton aria-label="Show Locations" title="Show Locations" onClick={() => history.push(`${location}${customer}/${params.id}`)}>
          <LocationCity />
        </IconButton>
      ),
    },
  ];

  useEffect(() => {
    customerService
      .getAll()
      .then((response) => setCustomerList(response.data))
      .catch((error: any) => {
        toast.error("There was an error while getting customers list!!");
        console.log(error)
      });
  }, [setCustomerList]);

  return (
    <div className="customer-list">
      <PageTitle title="Customers" />
      <br/><br/><br/><br/><br/><br/><br/>
      <Grid container spacing={2} className="main-content less-content">
        <Grid xs={12} sx={{ m: 1 }} item>
          <DataGrid style={{ height: '640px', width: '100%' }} rows={customerList} columns={columns} pageSize={10} rowsPerPageOptions={[10]} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Customers;
