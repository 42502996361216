import { Button, FormControl, InputLabel, NativeSelect, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { SketchPicker } from 'react-color';
import { Controller, useForm } from 'react-hook-form';
import { presetColors } from '../utils/appConstants';

const CreateButton = (props: any) => {
  const { createButton, selectedButton } = props;
  const { handleSubmit, register, reset, setValue, control } = useForm();
  const onSubmit = (data: any) => {
    createButton(data);
  };

  useEffect(() => {
    if (selectedButton) {
      Object.entries(selectedButton).forEach(([name, value]: any) => setValue(name, value));
    }else{
      reset({ title: '', type: 'always_show', responseText: '', color: '' });
    }
  }, [selectedButton, setValue, reset]);

  return (
    <div className="create-button">
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <FormControl fullWidth sx={{ m: 1 }}>
          <TextField
            id="location-name"
            label="Button Title"
            variant="standard"
            {...register('title')}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={!!selectedButton}
          />
        </FormControl>
        <FormControl fullWidth sx={{ m: 1 }}>
          {/* <InputLabel id="order-label">Order</InputLabel>
            <Input */}
          <TextField
            id="ordinal"
            label="Order"
            type="number"
            variant="standard"
            max
            {...register('ordinal')}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <FormControl fullWidth sx={{ m: 1 }}>
          <InputLabel id="type-select">Button Type</InputLabel>
          <NativeSelect
            id="type"
            variant="outlined"
            {...register('type')}
          >
            <option value="show_on_new">Show on new</option>
            <option value="always_show">Always Show</option>
          </NativeSelect>
        </FormControl>
        <FormControl fullWidth sx={{ m: 1 }}>
          <TextField
            id="response-text"
            label="Response Text"
            variant="standard"
            {...register('responseText')}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <FormControl fullWidth sx={{ m: 1 }}>
          <Controller name="color" control={control} render={({ field: props }) => <SketchPicker presetColors={presetColors} {...props} onChange={(color) => props.onChange(color.hex)} color={props.value} />}/>
        </FormControl>
        <Button variant="contained" type="submit" sx={{ m: 1 }}>
          Submit
        </Button>
      </form>
    </div>
  );
};

export default CreateButton;
