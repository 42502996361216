import axios from 'axios';
import { loginRequest } from '../config/appConfig';
import { msalInstance } from '../reduxStore';
const baseURL = process.env.REACT_APP_BASE_URL;
const axiosInstance = axios.create({ baseURL });

const getToken = async () => {
  const account = msalInstance.getActiveAccount();

  if (!account) {
      throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
  }

  const response = await msalInstance.acquireTokenSilent({
      account: account,
      ...loginRequest
  });

  return response.accessToken;
}

axiosInstance.interceptors.request.use(
  async function (config: any) {
    // Do something before request is sent
    const accessToken = await getToken();
    config.headers = {
      'Content-type': 'application/json',
      Accept: 'application/json',
    };

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

export default axiosInstance;