import React from 'react';
import { Button, FormControl, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { settingLabels } from '../utils/appConstants';

const CreateSetting = (props: any) => {
  const { handleSubmit, register, reset } = useForm();
  const { createSetting, settingName } = props;
  const onSubmit = (data: any) => {
    if (data) {
      createSetting({ name: settingName, value: data.value });
      reset({ value: '' });
    }
  };
  return (
    <div className="create-tag">
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <FormControl fullWidth sx={{ m: 1 }}>
          <TextField id="value" label={settingLabels[settingName]} variant="filled" {...register('value')} />
        </FormControl>
        <Button variant="contained" type="submit" sx={{ m: 1 }}>
          Submit
        </Button>
      </form>
    </div>
  );
};

export default CreateSetting;
