import types from '../types/common';

export const setAccessToken = (value: string) => ({
  type: types.SET_ACCESS_TOKEN,
  value,
});

export const setAuthorityURL = (value: string) => ({
  type: types.SET_AUTHORITY_URL,
  value,
});

const exportObject = { setAccessToken, setAuthorityURL };

export default exportObject;
