export const PRODUCT_NAME: string = 'ohWaitor Support Console';
export const buildTypeConstants = {
  DEVELOPMENT: 'development',
  PRODUCTION: 'production',
  STAGING: 'staging',
  TESTING: 'test',
};

const getMarks = (min: number, max: number) => {
  return [
    {
      value: min,
      label: min,
    },
    {
      value: max,
      label: max,
    },
  ];
};

export const settingLabels: any = {
  guest_name_type: 'Guest Prefix',
  tag_name: 'Tag Group',
};

export const settingsConstants: any = {
  tag_group: 'tag_name',
  settingControl: {
    message_display_count: { type: 'slider', properties: { min: 3, max: 100, step: 1, marks: getMarks(3, 100) }, value: 0, label: 'Message display count' },
    alert_when_charge_level_below: { type: 'slider', properties: { min: 0, max: 100, step: 1, marks: getMarks(0, 100) }, value: 0, label: 'Alert when Charge Level Below' },
    alert_when_on_battery: { type: 'checkbox', properties: {}, value: false, label: 'Alert When on Battery' },
    ping_ack_max_alert: { type: 'slider', properties: { min: 1, max: 10, step: 1, marks: getMarks(1, 10) }, label: 'Max Alert Count for Pink Ack Failure' },
    ping_device: { type: 'checkbox', properties: {}, value: false, label: 'Ping Device' },
    stripe_account: { type: 'text', properties: {}, value: null, label: 'Stripe Account' },
    message_delivery: { type: 'checkbox', properties: {}, value: false, label: 'Message Delivery' },
    message_delivery_failure_seconds: { type: 'slider', properties: { min: 20, max: 300, step: 1, marks: getMarks(20, 300) }, value: 0, label: 'Message Delivery Failure Seconds' },
    message_delivery_retry_delay_seconds: { type: 'slider', properties: { min: 10, max: 60, step: 1, marks: getMarks(10, 60) }, value: 0, label: 'Message Delivery Retry Delay Seconds' },
    message_delivery_success_offset_seconds: { type: 'slider', properties: { min: 30, max: 300, step: 1, marks: getMarks(30, 300) }, value: 0, label: 'Message Delivery Success Offset Seconds' },
    roaming_tag_name: { type: 'text', properties: {}, value: null, label: 'Roaming Tag' },
  },
};

export const drawerWidth: number = 300;

export const statusColumnNames: any = {
  id: 'Id',
  deviceName: 'Device Name',
  UsingBattery: 'Using Battery',
  ChargeLevel: 'Charge Level',
  ping_id: 'Ping Id',
  ping_alert_count: 'Ping Alert Count',
  ping_ack: 'Ping Ack',
  SignalR: 'Signal R',
};

export const presetColors: any = [
  { color: '#4ead49', title: 'Green' },
  { color: '#36b7d3', title: 'Blue' },
  { color: '#FFFF00', title: 'Yellow' },
  { color: '#FFA500', title: 'Orange' },
];

export const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
