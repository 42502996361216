import React, { useCallback } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import IButtonData from '../types/button.type';
import ButtonComponent from './ButtonComponent';

const ListButtons = (props: any) => {
  const { list, setList, selectedButton, selectButton } = props;
  const moveButton = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const newList = [...list];
      newList.splice(dragIndex, 1);
      newList.splice(hoverIndex, 0, list[dragIndex]);
      setList(newList);
    },
    [setList, list],
  );

  const deleteButton = useCallback(
    (index: number) => {
      const newList = [...list];
      newList.splice(index, 1);
      setList(newList);
    },
    [list, setList],
  );

  const renderButton = useCallback(
    (buttonItem: IButtonData, index: number) => (
      <ButtonComponent key={buttonItem.title} ordinal={index} title={buttonItem.title} color={buttonItem.color} type={buttonItem.type} responseText={buttonItem.responseText} moveButton={moveButton} deleteButton={deleteButton} selectButton={selectButton} selectedButton={selectedButton} />
    ),
    [moveButton, deleteButton, selectButton, selectedButton],
  );

  return (
    <div>
      <DndProvider backend={HTML5Backend}>{list.map((listItem: IButtonData, index: number) => renderButton(listItem, index))}</DndProvider>
    </div>
  );
};

export default ListButtons;
