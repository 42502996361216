import React from 'react';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { DeleteOutlined } from '@mui/icons-material';
import ISettingsData from '../types/settings.type';
import { settingLabels } from '../utils/appConstants';

interface ListSettingProps {
  list: ISettingsData[]
  deleteSetting: any
  filter: string
}

const ListSettings = (props: ListSettingProps) => {
  const { list, deleteSetting, filter } = props;
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left" colSpan={2}>
              {settingLabels[filter] || filter}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list
            .filter((row: any) => row.name === filter)
            .map((row: any, index: number) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align="left">{row.value}</TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => deleteSetting(row)}>
                    <DeleteOutlined />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ListSettings;
