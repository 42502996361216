import React from 'react';
import { Typography } from '@mui/material';
import './PageTitle.scss';

const PageTitle = (props: any) => {
  const { title, children } = props;
  return (
    <div className="page-title-container">
      <Typography variant="h5" gutterBottom component="div" className="page-title">
        {title}
      </Typography>
      {children}
    </div>
  );
};

export default PageTitle;
