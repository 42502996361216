import React from 'react';
import { useMsal } from '@azure/msal-react';
import { Button } from '@mui/material';

function handleLogout(instance: any) {
  instance.logoutPopup().catch((e: any) => {
    console.error(e);
  });
}

/**
 * Renders a button which, when selected, will open a popup for logout
 */
const SignOutButton = () => {
  const { instance } = useMsal();

  return (
    <Button variant="contained" onClick={() => handleLogout(instance)}>
      Sign out
    </Button>
  );
};

export default SignOutButton;
