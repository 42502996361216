import { FC, useEffect, useState } from 'react';
import { Scheduler, Appointments, WeekView, AppointmentTooltip, AppointmentForm } from '@devexpress/dx-react-scheduler-material-ui';
import { useParams } from 'react-router';
import { all, response } from '../utils/routingConstants';
import SelectLocation from '../components/SelectLocation';
import { FormControl, Grid, InputLabel, MenuItem, Select, Paper } from '@mui/material';
import locationService from '../Services/location.service';
import { Typography } from '@mui/material';
import { ViewState } from '@devexpress/dx-react-scheduler';
import { getDayName, structureSchedule } from '../utils/stringUtils';
import './ViewResponses.scss';
import EditResponseLayout from '../components/EditResponseLayout';
import PageTitle from '../components/PageTitle';
import { toast } from 'react-toastify';

const DayScaleLayout: FC = (props: any) => {
  const { startDate } = props;
  const day = getDayName(startDate, 'en-us');
  return (
    <td>
      <div className="Cell-dayView day-scale-layout">
        <p className="Cell-dayOfWeek text-align-center">
          <Typography align="center">{day}</Typography>
        </p>
      </div>
    </td>
  );
};

const ViewResponses: FC = () => {
  const [scheduleItems, setScheduleItems] = useState<any>([]);
  const [type, setType] = useState('new_conversation');
  const { locationId } = useParams<any>();

  useEffect(() => {
    if (locationId && Number.isInteger(parseInt(locationId))) {
      locationService
        .getResponses(locationId, type)
        .then((res: any) => {
          const list: any[] = structureSchedule(res, locationId, setScheduleItems);
          setScheduleItems(list);
        })
        .catch((error) => {
          toast.error('There was an error while getting response list!!');
          console.log(error);
        });
    }
  }, [locationId, type]);

  const onChangeType = (event: any) => {
    const {
      target: { value },
    } = event;
    setType(value);
  };

  const ResponseLayout = (props: any) => <EditResponseLayout locationId={locationId} type={type} setScheduleItems={setScheduleItems} {...props} />;

  return (
    <div className="view-responses">
      <PageTitle title="Responses">
        <Grid container>
          <Grid xs={5} sx={{ p: 1 }} item>
            <SelectLocation locationId={locationId} destRoute={`${response}${all}`} />
          </Grid>
          <Grid xs={5} sx={{ p: 1 }} item>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="type-select">Type</InputLabel>
              <Select labelId="type-select" id="customer" variant="filled" label="Type" onChange={onChangeType} defaultValue={type}>
                <MenuItem value="new_conversation">new_conversation</MenuItem>
                <MenuItem value="ack_failure">ack_failure</MenuItem>
                <MenuItem value="ack_success">ack_success</MenuItem>
                <MenuItem value="menu">menu</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </PageTitle>
      <br/><br/><br/><br/><br/><br/><br/>
      <Grid container spacing={2} className="main-content">
        <Grid xs={12} sx={{ m: 1 }} item>
          <Paper>
            <Scheduler data={scheduleItems} >
              <ViewState currentDate="2022-04-17" />
              <WeekView startDayHour={0} endDayHour={24} cellDuration={60} dayScaleCellComponent={DayScaleLayout} />
              <Appointments />
              <AppointmentTooltip showOpenButton />
              <AppointmentForm basicLayoutComponent={ResponseLayout} />
            </Scheduler>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default ViewResponses;
