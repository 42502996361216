import { Button, Checkbox, FormControl, FormLabel, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

const EditNotification = (props: any) => {
  const { createNotification, selectedNotification } = props;
  const { handleSubmit, register, reset, setValue, control } = useForm();
  const onSubmit = (data: any) => {
    createNotification(data);
  };

  useEffect(() => {
    if (selectedNotification) {
      Object.entries(selectedNotification).forEach(([name, value]: any) => setValue(name, value));
    } else {
      reset({ contactInfo: '', active: false });
    }
  }, [selectedNotification, setValue, reset]);

  return (
    <div className="edit-notification">
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        {selectedNotification && selectedNotification.rowId && (
          <FormControl fullWidth sx={{ m: 1 }}>
            <TextField
              id="rowId"
              label="Row Id"
              type="Number"
              variant="standard"
              {...register('rowId')}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
            />
          </FormControl>
        )}
        <FormControl fullWidth sx={{ m: 1 }}>
          <TextField
            id="contactInfo"
            label="Contact Info"
            type="tel"
            variant="standard"
            {...register('contactInfo')}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <FormControl fullWidth sx={{ m: 1 }} style={{ display: 'flex', flexDirection: 'row' }}>
          <Controller name="active" control={control} render={({ field: props }) => <Checkbox {...props} checked={!!props.value} onChange={(e) => props.onChange(e.target.checked)} />} />
          <FormLabel sx={{ m: 1 }}>Active</FormLabel>
        </FormControl>
        <Button variant="contained" type="submit" sx={{ m: 1 }}>
          Submit
        </Button>
      </form>
    </div>
  );
};

export default EditNotification;
