import { FC, useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import type { XYCoord, Identifier } from 'dnd-core'
import { ItemTypes } from '../types/items.type'
import { IconButton } from '@mui/material'
import { DeleteOutline, Edit } from '@mui/icons-material'
import classNames from 'classnames';
import IButtonData from '../types/button.type';

import './ButtonComponent.scss';

export interface ButtonComponentProps {
  title: string | null
  key: string | null
  color: string | null | undefined
  type: string | null | undefined
  responseText: string | null | undefined
  ordinal: number
  moveButton: (dragIndex: number, hoverIndex: number) => void
  deleteButton: (index: number) => void
  selectButton: (buttonData: any) => void
  selectedButton: IButtonData
}

interface DragItem {
  ordinal: number
  title: string
  type: string
}
const ButtonComponent: FC<ButtonComponentProps> = ({ color, type, title, responseText, ordinal, moveButton, deleteButton, selectButton, selectedButton }) => {
  const ref = useRef<HTMLDivElement>(null)
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.ordinal
      const hoverIndex = ordinal
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      moveButton(dragIndex, hoverIndex)
      item.ordinal = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { title, ordinal, type, responseText, color}
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0.8 : 1
  drag(drop(ref))
  return (
    <div ref={ref} style={{ opacity }} data-handler-id={handlerId} className={classNames("button-component", {selected: selectedButton && selectedButton.title === title})}>
      <div>
        Title: {title} <br/>
        ResponseText: {responseText} <br/>
        type: {type} <br/>
        color: {color} <br/>
        Ordinal: {ordinal}
      </div>
      <IconButton onClick={()=>selectButton({ordinal, title, responseText, color, type})}><Edit/></IconButton>
      <IconButton onClick={()=>deleteButton(ordinal)}><DeleteOutline/></IconButton>
    </div>
  )
}

export default ButtonComponent;
