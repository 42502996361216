import React from 'react';
import { Autocomplete, FormControl, TextField } from '@mui/material';

const optionsArray = ['numeric', 'alpha'];

const GuestNamePrefix = (props: any) => {
  const { getValue, createSetting, settingName } = props;
  const valueObject = getValue();
  const onChangeHandler = (event: any, data: any) => {
    createSetting({ name: settingName, value: data || '' });
  };
  return (
    <FormControl fullWidth sx={{ m: 1 }}>
      <Autocomplete disablePortal id="guest-prefix-combo" onChange={onChangeHandler} value={valueObject?.value || ''} options={optionsArray} sx={{ width: 300 }} renderInput={(params) => <TextField {...params} label="Guest Prefix" />} />
    </FormControl>
  );
};

export default GuestNamePrefix;
