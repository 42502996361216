import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import locationService from '../Services/location.service';

const SelectTag = (props: any) => {
  const { locationId, deviceId, createTag } = props;
  const { handleSubmit, register } = useForm();
  const [tags, setTags] = useState([]);

  useEffect(() => {
    locationId &&
      locationService
        .getTags(locationId)
        .then((response: any) => {
          setTags(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
  }, [deviceId, locationId]);

  const onSubmit = (data: any) => {
    const { tag } = data;
    createTag(tag);
  };

  return (
    <div className="select-device">
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <FormControl fullWidth sx={{ m: 1 }}>
          <InputLabel id="customer-select">Tag</InputLabel>
          <Select labelId="customer-select" id="customer" variant="filled" label="Customer" {...register('tag')}>
            {Array.isArray(tags) &&
              tags.map((tagItem: string[], index: number) => (
                <MenuItem key={index} value={tagItem}>
                  {tagItem}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <Button variant="contained" type="submit" sx={{ m: 1 }}>
          Submit
        </Button>
      </form>
    </div>
  );
};

export default SelectTag;
