import React from 'react';
import Home from '../pages/Home';
import { Route, Switch } from 'react-router';
import PageLayout from '../Layouts/PageLayout';
import { all, buttons, create, createCustomerRoute, createLocationRoute, customer, defaultRoute, device, devices, location, notifications, response, settings, status, tags } from '../utils/routingConstants';
import CreateLocationPage from '../pages/CreateLocationPage';
import Customers from '../pages/Customers';
import Locations from '../pages/Locations';
import CreateCustomerPage from '../pages/CreateCustomerPage';
import ButtonsPage from '../pages/ButtonsPage';
import PrivateRoute from './PrivateRoute';
import { useIsAuthenticated } from '@azure/msal-react';
import Tags from '../pages/Tags';
import Devices from '../pages/Devices';
import Settings from '../pages/Settings';
import CreateResponse from '../pages/CreateResponse';
import ViewResponses from '../pages/ViewResponses';
import AllSettings from '../pages/AllSettings';
import StatusPage from '../pages/StatusPage';
import NotificationPage from '../pages/NotificationPage';

const Routes = () => {
  const isAuthenticated = useIsAuthenticated();
  return (
    <PageLayout>
      <Switch>
        <Route exact path={defaultRoute} component={Home} />
        <PrivateRoute exact path={`${customer}${all}`} isAuthenticated={isAuthenticated}>
          <Customers />
        </PrivateRoute>
        <PrivateRoute exact path={createCustomerRoute} isAuthenticated={isAuthenticated}>
          <CreateCustomerPage />
        </PrivateRoute>
        <PrivateRoute exact path={createLocationRoute} isAuthenticated={isAuthenticated}>
          <CreateLocationPage />
        </PrivateRoute>
        <PrivateRoute exact path={`${location}${all}`} isAuthenticated={isAuthenticated}>
          <Locations />
        </PrivateRoute>
        <PrivateRoute exact path={`${location}${customer}/:customerId`} isAuthenticated={isAuthenticated}>
          <Locations />
        </PrivateRoute>
        <PrivateRoute path={`${location}${buttons}`} isAuthenticated={isAuthenticated}>
          <ButtonsPage />
        </PrivateRoute>
        <PrivateRoute exact path={`${location}/:locationId${buttons}`} isAuthenticated={isAuthenticated}>
          <ButtonsPage />
        </PrivateRoute>
        <PrivateRoute exact path={`${location}${tags}`} isAuthenticated={isAuthenticated}>
          <Tags />
        </PrivateRoute>
        <PrivateRoute exact path={`${location}/:locationId${tags}`} isAuthenticated={isAuthenticated}>
          <Tags />
        </PrivateRoute>
        <PrivateRoute exact path={`${location}${devices}`} isAuthenticated={isAuthenticated}>
          <Devices />
        </PrivateRoute>
        <PrivateRoute exact path={`${location}/:locationId${devices}`} isAuthenticated={isAuthenticated}>
          <Devices />
        </PrivateRoute>
        <PrivateRoute exact path={`${location}${settings}/all`} isAuthenticated={isAuthenticated}>
          <AllSettings />
        </PrivateRoute>
        <PrivateRoute exact path={`${location}/:locationId${settings}/all`} isAuthenticated={isAuthenticated}>
          <AllSettings />
        </PrivateRoute>
        <PrivateRoute exact path={`${location}${settings}/:settingName`} isAuthenticated={isAuthenticated}>
          <Settings />
        </PrivateRoute>
        <PrivateRoute exact path={`${location}/:locationId${settings}/:settingName`} isAuthenticated={isAuthenticated}>
          <Settings />
        </PrivateRoute>
        <PrivateRoute exact path={`${location}${device}${tags}`} isAuthenticated={isAuthenticated}>
          <Tags />
        </PrivateRoute>
        <PrivateRoute exact path={`${location}/:locationId${device}${tags}`} isAuthenticated={isAuthenticated}>
          <Tags />
        </PrivateRoute>
        <PrivateRoute exact path={`${location}/:locationId${device}/:deviceId${tags}`} isAuthenticated={isAuthenticated}>
          <Tags />
        </PrivateRoute>
        <PrivateRoute exact path={`${location}${response}${create}`} isAuthenticated={isAuthenticated}>
          <CreateResponse />
        </PrivateRoute>
        <PrivateRoute exact path={`${location}/:locationId${response}${create}`} isAuthenticated={isAuthenticated}>
          <CreateResponse />
        </PrivateRoute>
        <PrivateRoute exact path={`${location}${response}${all}`} isAuthenticated={isAuthenticated}>
          <ViewResponses />
        </PrivateRoute>
        <PrivateRoute exact path={`${location}/:locationId${response}${all}`} isAuthenticated={isAuthenticated}>
          <ViewResponses />
        </PrivateRoute>
        <PrivateRoute exact path={`${location}${status}`} isAuthenticated={isAuthenticated}>
          <StatusPage />
        </PrivateRoute>
        <PrivateRoute exact path={`${location}/:locationId${status}`} isAuthenticated={isAuthenticated}>
          <StatusPage />
        </PrivateRoute>
        <PrivateRoute exact path={`${location}${notifications}`} isAuthenticated={isAuthenticated}>
          <NotificationPage />
        </PrivateRoute>
        <PrivateRoute exact path={`${location}/:locationId${notifications}`} isAuthenticated={isAuthenticated}>
          <NotificationPage />
        </PrivateRoute>
      </Switch>
    </PageLayout>
  );
};

export default Routes;
