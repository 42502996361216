import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';

import createRootReducer from './rootReducer';
import { buildTypeConstants } from './utils/appConstants';
import { msalConfig } from './config/appConfig';
import { PublicClientApplication } from '@azure/msal-browser';

export const history = createBrowserHistory({ basename: '/' });
export const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = composeWithDevTools({ name: 'scs' });
const reducer = createRootReducer(history);
const enhancedCompose = process.env.NODE_ENV === buildTypeConstants.PRODUCTION ? compose : composeEnhancers;

export const middleware = enhancedCompose(applyMiddleware(sagaMiddleware, routerMiddleware(history)));
export const store = createStore(reducer, middleware);

const configObject = {
  ...msalConfig,
  auth: {
    clientId: process.env.REACT_APP_MSAL_CONFIG_CLIENT_ID || '',
    authority: process.env.REACT_APP_MSAL_CONFIG_AUTHORITY || '',
    redirectUri: process.env.REACT_APP_MSAL_CONFIG_REDIRECT_URI || '',
  },
};

export const msalInstance = new PublicClientApplication(configObject);
