import React from 'react';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { DeleteOutlined, Edit } from '@mui/icons-material';
import classNames from 'classnames';

import './ListDevices.scss';

const ListDevices = (props: any) => {
  const { list, deleteDevice, selectDevice, selectedDevice } = props;

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Device Name</TableCell>
            <TableCell align="left">Serial Number</TableCell>
            <TableCell align="left" colSpan={2}>
              ID
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((row: any, index: number) => (
            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} className={classNames({ 'selected-row': selectedDevice && selectedDevice.id === row.id })}>
              <TableCell align="left">{row.name}</TableCell>
              <TableCell align="left">{row.serialNumber}</TableCell>
              <TableCell align="left">{row.id}</TableCell>
              <TableCell align="center">
                <IconButton onClick={() => selectDevice({ name: row.name, serialNumber: row.serialNumber, id: row.id })}>
                  <Edit />
                </IconButton>
                <IconButton onClick={() => deleteDevice(index)}>
                  <DeleteOutlined />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ListDevices;
