import React from 'react';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';

const TableComponent = (props: any) => {
  const { columns, data, title, onRowSelectionChange, onRowClick, expandableRows = false, renderExpandableRow, selectableRows = 'none' } = props;
  const options: MUIDataTableOptions = {
    download: false,
    print: false,
    onRowSelectionChange,
    onRowClick,
    customToolbarSelect: () => <></>,
    selectableRows,
    expandableRowsHeader: true,
    expandableRows,
    renderExpandableRow,
  };

  return (
    <div style={{ maxWidth: '100%' }}>
      <MUIDataTable title={title} data={data} columns={columns} options={options} />
    </div>
  );
};

export default TableComponent;
