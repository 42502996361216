import { Grid } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";
import locationService from "../Services/location.service";
import { IResponseData } from "../types/response.type";
import { getResponseListOnDelete, getResponseListOnEdit,  structureSchedule } from "../utils/stringUtils";
import ResponseForm from "./ResponseForm";

const EditResponseLayout: FC = (props: any) => {
  const { appointmentData, locationId, type, setScheduleItems } = props;
  const [responseList, setResponseList] = useState<IResponseData[]>([]);

  useEffect(() => {
    locationService
      .getResponses(locationId, type)
      .then((res: any) => {
        setResponseList(res.data)
      })
      .catch((error) => {
        console.log(error);
        toast.error("There was an error while getting responses!!");
      });
  }, [type, locationId, setScheduleItems]);


  const updateResponses = (newList: IResponseData[], localType: string) =>{
    locationService.updateResponses(locationId, localType, newList).then(() => {
      toast('Responses List updated!!');
      locationService
        .getResponses(locationId, localType)
        .then((res: any) => {
          const list: any[] = structureSchedule(res, locationId, setScheduleItems);
          setScheduleItems(list);
        })
        .catch((error) => {
          toast.error("There was an error while getting responses!!");
          console.log(error);
        });
    }).catch((error) => {
      toast.error("There was an error while updating responses!!");
      console.log(error);
    })
  }

  const onSubmit = (data: IResponseData) => {
    const {type: localType} = data;
    // if(isOverLaps(responseList, data)){
    //   return;
    // }
    const newList = getResponseListOnEdit(responseList, data);
    updateResponses(newList, localType);
  }

  const onDelete = () => {
    const {type: localType} = appointmentData;
    const newList = getResponseListOnDelete(responseList, appointmentData);
    updateResponses(newList, localType);
  }

  return (
    <div className="edit-custom-response">
      <Grid container spacing={2}>
        <Grid xs={12} sx={{ p: 1 }}>
          <ResponseForm onSubmit={onSubmit} formData={{...appointmentData, type}} isDelete={true} onDelete={onDelete} editMode={true}/>
        </Grid>
      </Grid>
    </div>
  );
};

export default EditResponseLayout