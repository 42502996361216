import { Container, createTheme } from '@mui/material';
import * as React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeftOutlined';
import AppBar from '../components/AppBar';
import SideDrawer from '../components/SideDrawer';
import { List } from '@mui/material';
import MainListItems from '../components/listItems';
import { useIsAuthenticated } from '@azure/msal-react';
import SignInButton from '../components/SignInButton';
import SignOutButton from '../components/SignOutButton';
import black from '@mui/material/colors/blueGrey';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const mdTheme = createTheme({
  palette: {
    secondary: black,
  },
});

const PageLayout = (props: any) => {
  const isAuthenticated = useIsAuthenticated();
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  return (
    <div className="scs-page-layout">
      <ThemeProvider theme={mdTheme}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <AppBar position="absolute" open={open && isAuthenticated} color={process.env.REACT_APP_ENVIRONMENT === 'staging' ? 'primary' : 'secondary'}>
            <Toolbar
              sx={{
                pr: '24px', // keep right padding when drawer closed
              }}
            >
              {isAuthenticated && (
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  onClick={toggleDrawer}
                  sx={{
                    marginRight: '36px',
                    ...(open && { display: 'none' }),
                  }}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                Support Console | OhWaiter | {process.env.REACT_APP_ENVIRONMENT}
              </Typography>
              <IconButton color="inherit">{isAuthenticated ? <SignOutButton /> : <SignInButton />}</IconButton>
            </Toolbar>
          </AppBar>
          {isAuthenticated && (
            <SideDrawer variant="permanent" open={open}>
              <Toolbar
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  px: [1],
                }}
              >
                <IconButton onClick={toggleDrawer}>
                  <ChevronLeftIcon />
                </IconButton>
              </Toolbar>
              <Divider />
              <List component="nav">
                <MainListItems />
              </List>
            </SideDrawer>
          )}
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) => (theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900]),
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
            }}
          >
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 0, mb: 4 }}>
              <div className="page-content">{props.children}</div>
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
      <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </div>
  );
};

export default PageLayout;
