import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import CreateDevice from '../components/CreateDevice';
import ListDevices from '../components/ListDevices';
import PageTitle from '../components/PageTitle';
import SelectLocation from '../components/SelectLocation';
import locationService from '../Services/location.service';
import IDeviceData from '../types/device.type';
import { devices } from '../utils/routingConstants';

const Devices = () => {
  const paramsList = useParams < any > ();
  const { locationId } = paramsList;
  const [devicesList, setDevicesList] = useState([]);

  const [selectedDevice, setSelectedDevice] = useState<any>();
  const selectDevice = (deviceData:any) => {
    if(selectedDevice && selectedDevice.id === deviceData.id){
      setSelectedDevice(null);
    }else{
      setSelectedDevice(deviceData)
    }
  };

  useEffect(() => {
    if (locationId) {
      locationService
        .getDevices(parseInt(locationId))
        .then((response: any) => {
          setDevicesList(response.data);
        })
        .catch((e) => {
          toast.error("There was an error while getting devices list!!");
          console.log(e);
        });
    }
  }, [locationId]);

  const updateDeviceList = (list:IDeviceData[]) => {
    if(Array.isArray(list)){
      locationService.updateDevices(parseInt(locationId), list).then(()=>{
        toast('Devices List updated!!');
        locationService
        .getDevices(parseInt(locationId))
        .then((response: any) => {
          setDevicesList(response.data);
        })
        .catch((e) => {
          toast.error("There was an error while getting devices list!!");
          console.log(e);
        });
      }).catch((e)=>{
        toast.error("There was an error while updating devices list!!");
        console.log(e);
      });
    }
  }

  const createDevice = (deviceItem: IDeviceData) =>{
    const finalList: IDeviceData[]= [...devicesList];
    const {id} = deviceItem;

    if(selectedDevice){
      const selectedIndex = finalList.findIndex((item: IDeviceData)=>item.id===id);
      finalList[selectedIndex] = deviceItem;
    }else{
      finalList.push(deviceItem);
    }
    setSelectedDevice(null);
    updateDeviceList(finalList);
  }

  const deleteDevice = (index:number) => {
    const newList = [...devicesList];
    newList.splice(index, 1);
    updateDeviceList(newList);
  }
  
  return (
    <div className="devices-page">
      <PageTitle title="Devices">
        <Grid container>
          <Grid xs={12} sx={{ m: 1 }} item>
            <SelectLocation locationId={locationId} destRoute={devices} />
          </Grid>
        </Grid>
      </PageTitle>
      <br/><br/><br/><br/><br/><br/><br/>
      <Grid container spacing={2} className="main-content">
        <Grid xs={6} item>
          <ListDevices list={devicesList} deleteDevice={deleteDevice} selectDevice={selectDevice} selectedDevice={selectedDevice}/>
        </Grid>
        <Grid xs={6} item>
          <CreateDevice createDevice={createDevice} selectedDevice={selectedDevice}/>
        </Grid>
      </Grid>
    </div>
  )
}

export default Devices;