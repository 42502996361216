import { Grid } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import CreateTag from '../components/CreateTag';
import ListTags from '../components/ListTags';
import PageTitle from '../components/PageTitle';
import SelectDevice from '../components/SelectDevice';
import SelectLocation from '../components/SelectLocation';
import SelectTag from '../components/SelectTag';
import locationService from '../Services/location.service';
import { device, tags } from '../utils/routingConstants';

const Tags = () => {
  const paramsList = useParams<any>();
  const { locationId, deviceId } = paramsList;
  const [tagsList, setTagsList] = useState([]);
  const history = useHistory();
  const isDeviceTags = history.location.pathname.includes(device);
  const getTagsList = useCallback(() => {
    if (locationId && Number.isInteger(parseInt(locationId))) {
      if (isDeviceTags) {
        if (deviceId && deviceId !== 'undefined') {
          locationService
            .getDeviceTags(locationId, deviceId)
            .then((response: any) => {
              setTagsList(response.data);
            })
            .catch((e) => {
              toast.error('There was an error while getting tags list!!');
              console.log(e);
            });
        }
      } else {
        locationService
          .getTags(locationId)
          .then((response: any) => {
            setTagsList(response.data);
          })
          .catch((e) => {
            toast.error('There was an error while getting tags list!!');
            console.log(e);
          });
      }
    }
  }, [deviceId, locationId, isDeviceTags]);

  const postTagsList = useCallback(
    (list) => {
      if (locationId && Number.isInteger(parseInt(locationId))) {
        if (isDeviceTags) {
          if (deviceId && deviceId !== 'undefined') {
            locationService.updateDeviceTags(locationId, deviceId, list).then(() => {
              toast('Tags List updated!!');
              getTagsList();
            });
          }
        } else {
          return locationService.updateTags(locationId, list).then(() => {
            toast('Tags List updated!!', {
              toastId: 'tags'
            });
            getTagsList();
          });;
        }
      }
    },
    [deviceId, locationId, isDeviceTags, getTagsList],
  );

  useEffect(() => {
    getTagsList();
  }, [getTagsList]);

  const updateTagList = (list: string[]) => {
    if (Array.isArray(list)) {
      postTagsList(list);
    }
  };

  const createTag = (tagItem: string) => {
    const finalList: string[] = [...tagsList];
    finalList.push(tagItem);
    updateTagList(finalList);
  };

  return (
    <div className="tags-page">
      <PageTitle title="Tags">
        <Grid container>
          <Grid xs={6} item>
            <SelectLocation locationId={locationId} destRoute={isDeviceTags ? `${device}${tags}` : tags} />
          </Grid>
          {isDeviceTags && (
            <Grid xs={6} item>
              <SelectDevice locationId={locationId} deviceId={deviceId} destRoute={tags} />
            </Grid>
          )}
        </Grid>
      </PageTitle>
      <br/><br/><br/><br/><br/><br/><br/>
      <Grid container spacing={2} className="main-content">
        <Grid xs={6} item>
          <ListTags list={tagsList} updateTagList={updateTagList} isDeleteEnabled={true} />
        </Grid>
        <Grid xs={6} item>{isDeviceTags ? <SelectTag locationId={locationId} deviceId={deviceId} createTag={createTag} /> : <CreateTag createTag={createTag} />}</Grid>
      </Grid>
    </div>
  );
};

export default Tags;
