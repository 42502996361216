import { Button, FormControl, Grid, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import PageTitle from '../components/PageTitle';
import customerService from '../Services/customer.service';

const CreateCustomerPage = () => {
  const { handleSubmit, register, reset } = useForm();

  const onSubmit = (data: any) => {
    customerService
      .create(data)
      .then(() => {
        toast("Customer created!!");
        reset({ customerName: '' });
      })
      .catch((e) => {
        toast("There was an error while creating a customer!!");
        console.log(e)
      });
  };
  return (
    <div className="create-customer">
      <PageTitle title="Create Customer" />
      <br/><br/><br/><br/><br/><br/><br/>
      <Grid container spacing={2} className="main-content less-content">
        <Grid xs={12} sx={{ m: 1 }} item>
          <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <FormControl fullWidth sx={{ m: 1 }}>
              <TextField id="customer-name" label="Customer Name" variant="filled" {...register('customerName')} />
            </FormControl>
            <Button variant="contained" type="submit" sx={{ m: 1 }}>
              Submit
            </Button>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default CreateCustomerPage;
