import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, TableHead } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { statusColumnNames } from '../utils/appConstants';
import TableComponent from './TableComponent';

const StatusTable = (props: any) => {
  const { data } = props;
  const columns = [
    { name: 'deviceName', label: statusColumnNames['deviceName'] },
  ];
  const expandableRowComponent = (rowData: any, rowMeta: any) => {
    const device = data[rowMeta.rowIndex];
    const { settings } = device;

    return (
      <React.Fragment>
        <tr>
          <td colSpan={3} style={{ padding: '1rem', background: '#f5f5f5' }}>
            <TableContainer component={Paper}>
              <Table style={{ minWidth: '650' }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Setting name</TableCell>
                    <TableCell>Setting Value</TableCell>
                    <TableCell>Current At</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {settings.map((settingItem: any) => {
                    const { settingId, name, value, currentAt } = settingItem;
                    return (
                      <TableRow>
                        <TableCell component="th" variant="head" scope="row" key={settingId}>
                          {statusColumnNames[name]}
                        </TableCell>
                        <TableCell>{value}</TableCell>
                        <TableCell>{moment(currentAt).fromNow()}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </td>
        </tr>
      </React.Fragment>
    );
  };

  return <TableComponent columns={columns} data={data} title="Devices List" expandableRows={true} renderExpandableRow={expandableRowComponent} />;
};

export default StatusTable;
