import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import PageTitle from '../components/PageTitle';
import customerService from '../Services/customer.service';
import locationService from '../Services/location.service';

const CreateLocationPage = () => {
  const { handleSubmit, register, reset } = useForm();
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    customerService
      .getAll()
      .then((response: any) => setCustomers(response.data))
      .catch((e) => {
        console.log(e);
        toast.error("There was an error while getting location list!!");
      });
  }, []);

  const onSubmit = (data: any) => {
    locationService
      .create(data)
      .then(() => {
        toast('Location Created!!');
        reset({ customerId: '', locationName: '', timeZone: '' });
      })
      .catch((e) => {
        toast.error("There was an error while updating location list!!");
        console.log(e)
      });
  };

  return (
    <div className="create-location">
      <PageTitle title="Create Location" />
      <br/><br/><br/><br/><br/><br/><br/>
      <Grid container spacing={2} className="main-content less-content">
        <Grid xs={12} sx={{ m: 1 }} item>
          <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <FormControl fullWidth sx={{ m: 1 }}>
              <TextField id="location-name" label="Location Name" variant="filled" {...register('locationName')} />
            </FormControl>
            <FormControl fullWidth sx={{ m: 1 }}>
              <InputLabel id="customer-select">Customer</InputLabel>
              <Select labelId="customer-select" id="customer" variant="filled" label="Customer" {...register('customerId')}>
                {Array.isArray(customers) &&
                  customers.map((customerItem: any) => (
                    <MenuItem key={customerItem.id} value={customerItem.id}>
                      {customerItem.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ m: 1 }}>
              <TextField id="time-zone" label="Time Zone" variant="filled" {...register('timeZone')} />
            </FormControl>
            <Button variant="contained" type="submit" sx={{ m: 1 }}>
              Submit
            </Button>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default CreateLocationPage;
