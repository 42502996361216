import { useState } from 'react';
import { Button, Checkbox, FormControl, FormHelperText, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, Slider, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { days } from '../utils/appConstants';

const minDistance = 1;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ResponseForm = (props: any) => {
  const { onSubmit, formData, isDelete, onDelete, editMode=false } = props;
  const { startHour, endHour, enabledDaysOfWeek, template, type, rowId } = formData;
  const [isDaysError, setIsDaysError] = useState(false);
  const { handleSubmit, register } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      template,
      type
    },
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const [hourList, setHourList] = useState<number[]>([startHour, endHour]);
  const [dayList, setDayList] = useState<string[]>(enabledDaysOfWeek ? enabledDaysOfWeek.split(',') : []);

  const submitHandler = (data: any) => {
    const { type, template } = data;
    if(dayList.length===0){
      setIsDaysError(true);
      return;
    }
    const responseData = {
      rowId,
      type,
      template,
      startHour: hourList[0],
      endHour: hourList[1],
      enabledDaysOfWeek: dayList.toString()
    }
    setIsDaysError(false);
    onSubmit(responseData);
  };

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setDayList(value);
  };

  const handleSliderChange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number,
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - minDistance);
        setHourList([clamped, clamped + minDistance]);
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        setHourList([clamped - minDistance, clamped]);
      }
    } else {
      setHourList(newValue as number[]);
    }
  };

  return (
    <div className="response-form">
      <form autoComplete="off" onSubmit={handleSubmit(submitHandler)}>
        <FormControl fullWidth sx={{ m: 1 }}>
          <InputLabel id="type-select">Type</InputLabel>
          <Select labelId="type-select" id="customer" variant="filled" label="Type" {...register('type')} value={type} disabled={editMode}>
            <MenuItem value="new_conversation">new_conversation</MenuItem>
            <MenuItem value="ack_failure">ack_failure</MenuItem>
            <MenuItem value="ack_success">ack_success</MenuItem>
            <MenuItem value="menu">menu</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ m: 1 }}>
          <TextField multiline id="response-template" label="Response Template" variant="filled" {...register('template')} rows={5} />
        </FormControl>
        <FormControl fullWidth sx={{ m: 2 }}>
          <Typography gutterBottom>Time Range (24Hr)</Typography>
          <Slider value={hourList} onChange={handleSliderChange} valueLabelDisplay="auto" disableSwap min={0} max={23} step={1} marks />
        </FormControl>
        <FormControl fullWidth sx={{ m: 1 }} error={isDaysError}>
          <InputLabel id="day-select">Days</InputLabel>
          <Select labelId="day-select" id="day-select" label="Days" multiple value={dayList} onChange={handleChange} input={<OutlinedInput label="Days" />} renderValue={(selected) => selected.map((selectedItem: string) => days[parseInt(selectedItem)]).join(', ')} MenuProps={MenuProps}>
            {days.map((day, index) => (
              <MenuItem key={index} value={index.toString()}>
                <Checkbox checked={dayList.includes(index.toString())} />
                <ListItemText primary={day} />
              </MenuItem>
            )
            )}
          </Select>
          {isDaysError && <FormHelperText>Day selection is required.</FormHelperText>}
        </FormControl>
        <Button variant="contained" type="submit" sx={{ m: 1 }}>
          Submit
        </Button>
        {isDelete && <Button variant="contained" color='error' onClick={onDelete} sx={{ m: 1 }}>
          Delete
        </Button>}
      </form>
    </div>
  );
};

ResponseForm.defaultProps = {
  formData: {
    rowId: null,
    startHour: 0,
    endHour: 23,
    type: 'new_conversation',
  },
  isDelete: false,
}

export default ResponseForm;
