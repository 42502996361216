import { useEffect, useState } from 'react';
import { ILocationData } from '../types/location.type';
import locationService from '../Services/location.service';
import { useParams } from 'react-router';
import customerService from '../Services/customer.service';
import PageTitle from '../components/PageTitle';
import { toast } from 'react-toastify';
import TableComponent from '../components/TableComponent';
import { Grid } from '@mui/material';

const Locations = () => {
  const [locationList, setLocationList] = useState< Array < ILocationData >>([]);
  const paramsList = useParams<any>();

  const columns = [
    { name: 'id', label: 'Id' },
    { name: 'name', label: 'Location Name' },
    { name: 'customerName', label: 'Customer Name' },
    { name: 'timeZone', label: 'Time Zone' },
    { name: 'phoneNumbers', label: 'Phone Numbers', options: {customBodyRenderLite: (dataIndex: any, rowIndex: any) => locationList[dataIndex].phoneNumbers && locationList[dataIndex].phoneNumbers.toString()} },
  ];

  useEffect(() => {
    if (paramsList.customerId) {
      customerService
        .getLocations(paramsList.customerId)
        .then((response: any) => setLocationList(response.data))
        .catch((error: any) => {
          console.log(error);
          toast.error('There was an error while getting locations list!!');
        });
    } else {
      locationService
        .getAll()
        .then((response: any) => setLocationList(response.data))
        .catch((error: any) => {
          toast.error('There was an error while getting locations list!!');
          console.log(error);
        });
    }
  }, [paramsList.customerId]);

  return (
    <div className="location-list">
      <PageTitle title="Locations" />
      <br/><br/><br/><br/><br/><br/><br/>
      <Grid container spacing={2} className="main-content less-content">
        <Grid xs={12} sx={{ m: 1 }} item>
          <TableComponent data={locationList} columns={columns} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Locations;
