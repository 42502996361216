import api from './api';
import { ICreateCustomerData, ICustomerData } from '../types/customer.type';
import { ILocationData } from '../types/location.type';
const API_URL = '/customer'

class CustomerDataService {
    getAll() {
        return api.get<Array<ICustomerData>>("/customers");
    }
    create(data: ICreateCustomerData) {
        return api.post<Boolean>(API_URL, data);
    }

    getLocations(customerId: number) {
        return api.get<ILocationData>(`${API_URL}/${customerId}/locations`);
    }
}
export default new CustomerDataService();
